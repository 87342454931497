import React, { useState, useEffect } from 'react';
import { IoMdMenu, IoMdClose, IoMdCall, IoIosPin } from 'react-icons/io';
import { Link, withRouter, useLocation } from 'react-router-dom';

import moment from 'moment';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

import './style.css';

function DesktopNavbar() {
  const [isOpen, setIsOpen] = useState(true);
  const [bookingStart, setBookingStart] = useState(moment(new Date()));
  const [bookingEnd, setBookingEnd] = useState(moment(new Date()));
  const [bookingFocusedInput, setBookingFocusedInput] = useState(null);

  const { pathname } = useLocation();

  useEffect(() => {
    const handleScrollClose = () => {
      if (window.scrollY < 50) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }

    document.addEventListener('scroll', handleScrollClose);

    return () => {
      document.removeEventListener('scroll', handleScrollClose);
    }
  }, []);

  return (<>
    <div className={`fixed top-0 left-0 m-4 z-50 flex items-center`}>
      <button onMouseMove={() => {
        setIsOpen(true);
      }}
        aria-label="Menu"
        className="navbar-bg-color p-2 tailwind-block">
        <IoMdMenu className="text-4xl"></IoMdMenu>
      </button>
    </div>
    <div className={`navbar__bg-container ${isOpen ? 'visible' : ''}`}>
      <ul className="xl:container navbar__content pt-16 pb-2 tracking-20 uppercase mx-auto flex justify-center items-center text-center" role="navigation" >
        <li>
          <Link className={pathname === '/' ? 'active' : undefined} to="/">
            Home
          </Link>
        </li>
        <li>
          <Link className={pathname === '/rooms' ? 'active' : undefined} to="/rooms">
            Rooms
          </Link>
        </li>
        <li>
          <Link className={pathname === '/thingstodo' ? 'active' : undefined} to="/thingstodo">
            Nantucket
          </Link>
        </li>
        <li>
          <Link className={pathname === '/insider-guide' ? 'active' : undefined} to="/insider-guide">
            Insider's Guide
          </Link>
        </li>
        <li>
          <Link className={pathname === '/dining' ? 'active' : undefined} to="/dining">
            Dining
          </Link>
        </li>
        <li>
          <Link className={pathname === '/about' ? 'active' : undefined} to="/about">
            Manifesto
          </Link>
        </li>
        <li>
          <Link className={pathname === '/getting-here' ? 'active' : undefined} to="/getting-here">
            Getting Here
          </Link>
        </li>

        {/* <li>
          <Link className={pathname === '/press' && 'active'} to="/press">
            Press
          </Link>
        </li> */}
        {/* <li>
          <Link className={pathname === '/last-minute-specials' ? 'active':undefined} to="/last-minute-specials">
            Last-Minute Specials
          </Link>
        </li> */}
        <li>
          <a href="https://secure.thinkreservations.com/unioninn/gift-certificates?_ga=2.31693816.759327559.1701963417-1807304648.1620305221"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Gift Certificates Link"
          >
            Gift Certificates
          </a>
        </li>
      </ul>
      <div className={`fixed mt-4 mr-4 top-0 right-0 flex flex-row z-10`}>
        <DateRangePicker
          startDate={bookingStart}
          startDateId="start"
          endDate={bookingEnd}
          endDateId="end"
          onDatesChange={({ startDate, endDate }) => {
            setBookingStart(startDate);
            setBookingEnd(endDate);
          }}
          focusedInput={bookingFocusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={focusedInput => setBookingFocusedInput(focusedInput)}
        >
        </DateRangePicker>
        <a
          href={`https://secure.thinkreservations.com/unioninn/reservations/availability?start_date=${bookingStart && bookingStart.format('YYYY-MM-DD')}&end_date=${bookingEnd && bookingEnd.format('YYYY-MM-DD')}`}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Book Now Link"
          className="navbar-bg-color mr-2 btn-sm border-2 border-primary-blue uppercase">
          Book Now
        </a>
      </div>
    </div>
  </>
  );
}

function MobileNavbar() {
  const [isOpen, setIsOpen] = useState(false);

  const [isBottomOpen, setIsBottomOpen] = useState(true);

  const { pathname } = useLocation();

  useEffect(() => {
    const handleScrollClose = (e) => {
      if (window.scrollY < 100) {
        setIsBottomOpen(true);
      } else {
        setIsBottomOpen(false);
      }
    }

    document.addEventListener('scroll', handleScrollClose);

    return () => {
      document.removeEventListener('scroll', handleScrollClose);
    }
  }, []);

  return (<div className="fixed z-20">
    <div className={`fixed p-4 top-0 left-0 right-0 flex flex-row items-center`}>
      <button className="pointer-events-auto p-2 bg-white" aria-label="Menu" onClick={() => {
        setIsOpen(true);
      }}>
        <IoMdMenu className="icon"></IoMdMenu>
      </button>

      <a className="ml-auto text-xl btn bg-primary-blue text-white"
        href="https://secure.thinkreservations.com/unioninn/reservations/availability"
        rel="noopener noreferrer"
        target="_blank"
        aria-label="Book Now Link"
      >
        Book Now
      </a>
    </div>

    <div className={`tracking-20 uppercase bg-white p-8 py-16 mobile-nav__container ${isOpen ? 'visible' : ''}`} role="presentation">
      <IoMdClose className="absolute top-0 right-0 icon text-primary-blue m-8" onClick={() => {
        setIsOpen(false);
      }} />
      <ul className="navbar__content stack-l text-center leading-relaxed" role="navigation">
        <li>
          <Link className={pathname === '/' && 'active'} to="/" onClick={() => {
            setIsOpen(false);
          }} role="menu">
            Home
          </Link>
        </li>
        <li>
          <Link className={pathname === '/rooms' && 'active'} to="/rooms" onClick={() => {
            setIsOpen(false);
          }} role="menu">
            Rooms
          </Link>
        </li>
        <li>
          <Link className={pathname === '/thingstodo' && 'active'} to="/thingstodo" onClick={() => {
            setIsOpen(false);
          }} role="menu">
            Nantucket
          </Link>
        </li>
        <li>
          <Link className={pathname === '/insider-guide' && 'active'} to="/insider-guide" onClick={() => {
            setIsOpen(false);
          }} role="menu">
            Insider's Guide
          </Link>
        </li>
        <li>
          <Link className={pathname === '/dining' && 'active'} to="/dining" onClick={() => {
            setIsOpen(false);
          }} role="menu">
            Dining
          </Link>
        </li>
        <li>
          <Link className={pathname === '/about' && 'active'} to="/about" onClick={() => {
            setIsOpen(false);
          }} role="menu">
            Manifesto
          </Link>
        </li>
        <li>
          <Link className={pathname === '/getting-here' && 'active'} to="/getting-here" onClick={() => {
            setIsOpen(false);
          }} role="menu">
            Getting Here
          </Link>
        </li>
        {/* <li>
          <Link className={pathname === '/press' && 'active'} to="/press" onClick={() => {
            setIsOpen(false);
          }} role="menu">
            Press
          </Link>
        </li> */}
        {/* <li>
          <Link className={pathname === '/last-minute-specials' && 'active'} to="/last-minute-specials" onClick={() => {
            setIsOpen(false);
          }} role="menu">
            Last-Minute Specials
          </Link>
        </li> */}
        <li>
          <a href="https://secure.thinkreservations.com/unioninn/gift-certificates?_ga=2.31693816.759327559.1701963417-1807304648.1620305221"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Gift Certificates Link"
            role="menu"
          >
            Gift Certificates
          </a>
        </li>
      </ul>
    </div>
  </div>
  );
}

const NavBar = () => {

  return (
    <React.Fragment>
      <div className="hide-on-lg">
        <DesktopNavbar />
      </div>
      <div className="block lg:hidden">
        <MobileNavbar />
      </div>
    </React.Fragment>
  );
}

export default withRouter(NavBar);