import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { AiFillFacebook,AiFillGoogleCircle } from 'react-icons/ai';
import { FaTripadvisor, FaInstagram } from 'react-icons/fa';

import { Img } from "component/FrameL";

import BespokeLogo from "assets/bespoke_footer_icon.png";
import './style.css';

const Footer = ({ cmsData }) => {
    const { HotelName, HotelAddress1, HotelAddress2, PhoneLocal, Facebook, Instagram, Email, TripAdvisor } = cmsData;
    const GoogleReviewsLink = "https://www.google.com/travel/search?q=union%20inn%20nantucket%20ma%20google%20reviews&g2lb=4814050%2C4874190%2C4893075%2C4965990%2C10210222%2C72277293%2C72302247%2C72317059%2C72406588%2C72414906%2C72421566%2C72462234%2C72470899%2C72471280%2C72472051%2C72473841%2C72481459%2C72485658%2C72486593%2C72494250%2C72499705%2C72513513%2C72520079%2C72536387%2C72549171%2C72569093%2C72570850%2C72602734%2C72605013%2C72606237%2C72614662%2C72616120%2C72619927%2C72620306%2C72620956%2C72624691%2C72626061%2C72634629%2C72648289%2C72653659&hl=en-US&gl=us&ssta=1&ts=CAEaRwopEicyJTB4ODlmYWRkMjAwYTFlNDQwNzoweGIzODYzYjlkYTdiOGJhYzMSGhIUCgcI6A8QBxgHEgcI6A8QBxgIGAEyAhAA&qs=CAEyFENnc0l3X1hpdmRyempzT3pBUkFCOAJCCQnDurinnTuGs0IJCcO6uKedO4az&ap=ugEHcmV2aWV3cw&ictx=111&ved=0CAAQ5JsGahcKEwiwp_7c2pCHAxUAAAAAHQAAAAAQCw";
    return <>
        <footer className="relative bg-cover px-4 md:px-8 flex flex-row flex-wrap justify-between items-center z-0 footer__container text-white bg-primary-blue leading-normal">
            <div className="w-full sm:w-1/2 lg:w-4/12 text-center flex flex-col my-8">
                <span>
                    {HotelAddress1}
                </span>
                <span>
                    {HotelAddress2}
                </span>
                <span className="mt-4">
                    Phone: {PhoneLocal}
                </span>
                <span>
                    Email: <a className="text-white" href={`mailto:${Email}`}>{Email}</a>
                </span>
            </div>
            <div className="px-8 w-full sm:w-1/2 lg:w-4/12 ">
                <stack-l space="var(--s-4)" class="text-center items-center my-4 py-4">
                    <br />
                    <HashLink to="/about#email-signup" className="uppercase text-white">Email Signup</HashLink>
                    <p>For the latest news and last minute specials.</p>
                    <br />
                    <p className="uppercase">Follow Us</p>

                    <div className="flex flex-row items-center text-4xl gap-1">
                        <a href={TripAdvisor} target="_blank" aria-label="Trip Advisor Link" className="text-white" >
                            <FaTripadvisor></FaTripadvisor>
                        </a>
                        <a href={Facebook} target="_blank" aria-label="Facebook Link" className="text-white">
                            <AiFillFacebook></AiFillFacebook>
                        </a>
                        <a href={Instagram} target="_blank" aria-label="Instagram Link" className="text-white">
                            <FaInstagram></FaInstagram>
                        </a>
                        <a href={GoogleReviewsLink} target="_blank" aria-label='Google Reviews' className='text-white'>
                            <AiFillGoogleCircle></AiFillGoogleCircle>
                        </a>
                    </div>
                </stack-l>
            </div>
            <div className="w-full lg:w-4/12 flex items-center justify-around my-8">
                <stack-l space="var(--s-4)" class="text-center  px-4">
                    <Link className="text-white" to="/policies">Policies</Link>
                    <Link className="text-white" to="/health-policies">Covid 19 Health Policies</Link>
                    <Link className="text-white" to="/press">Press</Link>
                    <a aria-label="Gift Certificates Link" className="text-white" href="https://secure.thinkreservations.com/unioninn/gift-certificates">Gift Certificates</a>
                    <p>Copyright {new Date().getFullYear()} {HotelName} &reg;</p>
                </stack-l>
                <div className="flex flex-col items-center lg:items-end">
                    <Img isExternal={false} src={BespokeLogo} alt="Bespoke Logo" />
                </div>
            </div>
        </footer>
    </>;
};

export default Footer;
