import { fetchGet, fetchPost } from './helper';


export default class {
    static async getInstagramFeed() {
        const { data } = await fetchGet(`${process.env.REACT_APP_API_DOMAIN}/instagram-feed`);
        return data || [];
    }
    static sendContactUs(data, bookingType = 'General') {
        const { firstName, lastName, emailAddress, cellPhone = '', city = '', groupName = '', dateDesired = '', numberOfRooms = 0, questions = '', isSubscribed = false, captchaResponse } = data;

        const body = {
            firstName,
            lastName,
            emailAddress,
            cellPhone,
            city,
            groupName,
            dateDesired,
            numberOfRooms,
            questions,
            bookingType,
            isSubscribed,
            captchaResponse
        }
        return fetchPost(`${process.env.REACT_APP_API_DOMAIN}/bespoke-contact-form/contacts`, body);
    }
}
