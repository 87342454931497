import React, { Suspense, useEffect, useState } from 'react';
import { Switch, Route, useLocation, withRouter } from 'react-router-dom';
import Navbar from './container/NavBar';
import FavouriteManager from './container/FavouriteManager';
import Footer from './component/Footer';
import Loader from './component/Loader';
import { PopupControl } from 'component/PopupControl/PopupControl';

import { convertNullToUndefined } from './helpers';

const HomePage = React.lazy(() => import('./container/Home'));
const AboutPage = React.lazy(() => import('./container/About'));
const ThingsToDoPage = React.lazy(() => import('./container/ThingsToDo'));
// const SpecialsPage = React.lazy(() => import('./container/Specials'));
const GettingHerePage = React.lazy(() => import('./container/GettingHere'));
const PackagesPage = React.lazy(() => import('./container/Packages'));
const DiningPage = React.lazy(() => import('./container/Dining'));
const RoomsPage = React.lazy(() => import('./container/Rooms'));
const GalleryPage = React.lazy(() => import('./container/Gallery'));
const InsidersGuidePage = React.lazy(() => import('./container/InsidersGuide'));
const PoliciesPage = React.lazy(() => import('./container/Policies'));
const PressPage = React.lazy(() => import('./container/Press'));

function App({ history }) {
  const [data, setPageData] = useState(null);
  const location = useLocation();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_DOMAIN}/all`)
      .then(response => response.json())
      .then(data => {
        setPageData(convertNullToUndefined(data));
      }).catch(error => {
        // @todo handle error
        console.log('Something went wrong');
      });

    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, []);

  if (!data) {
    return <Loader></Loader>
  }

  const { Rooms = {}, Home, ThingsToDo = {}, About = {}, GettingHere, Specials, Policies, CoreData, PointsOfInterest, Gallery, Dining, Press, AmpRooms, AmpPackages, AmpLMS, Popup } = data;
  return (
    <Suspense fallback={<Loader></Loader>}>
      <div className="App">
      <a className="skip-to-content-link" href="#page-content" tabIndex={0}>
          Skip to content
        </a>
        {/* {generateSEO(Home.SEO)} */}
        {Popup && !Popup.Disabled && <PopupControl key={location.pathname} cmsData={Popup}></PopupControl>}
        <Navbar cmsData={{ CoreData }} />
        <Switch>
          <Route exact path="/rooms" component={() => <>
            <RoomsPage cmsData={Rooms} Rooms={AmpRooms} />
          </>} />
          <Route exact path="/thingstodo" component={() => <>
            <ThingsToDoPage cmsData={ThingsToDo} pointsOfInterestCmsData={PointsOfInterest} />
          </>} />
          <Route exact path="/dining" component={() => <>
            <DiningPage cmsData={Dining} />
          </>} />
          <Route exact path="/about" component={() => <>
            <AboutPage cmsData={About} />
          </>} />
          <Route exact path="/getting-here" component={() => <>
            <GettingHerePage cmsData={GettingHere} />
          </>} />
          <Route exact path="/insider-guide" component={() => <InsidersGuidePage cmsData={PointsOfInterest} />} />
          <Route exact path="/press" component={() => <PressPage homeCmsData={Home} cmsData={Press} />} />
          <Route exact path="/packages" component={() =>
            <>
              <PackagesPage cmsData={Specials} Packages={AmpPackages} contentType="packages" />
            </>} />
          <Route exact path="/policies" component={() => <PoliciesPage cmsData={Policies} />} />
          <Route exact path="/health-policies" component={() => <PoliciesPage type="health" cmsData={Policies} />} />
          <Route exact path="/last-minute-specials" component={() => {
            const { LmsHeroImage: HeroImage, LmsIntroduction: Introduction } = Specials;
            const LmsSpecials = {
              HeroImage,
              Introduction
            }
            // LMS is using same layout as packages
            return <>
              <PackagesPage cmsData={LmsSpecials} Packages={AmpLMS} contentType="lms" heading="Last Minute Specials" />
            </>
          }} />
          <Route path="/" component={() =>
            <>
              <HomePage cmsData={Home} />
            </>} />
        </Switch>
        <Footer cmsData={CoreData} />
        { <FavouriteManager CoreData={CoreData} Rooms={AmpRooms} LMS={AmpLMS} Packages={AmpPackages} /> }
      </div>
    </Suspense>
  );
}

export default withRouter(App);
