import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown';
import { useLocation } from 'react-router-dom';

import Modal from 'component/Modal';
import FrameL, { Img } from 'component/FrameL';
import './PopupControl.css';

const PopupDetailed = ({ cmsData }) => {
  const { Content, BackgroundImage, BoxColor, ShowButton, ButtonTextColor, ButtonColor, ButtonText, TextColor, ButtonLink } = cmsData;

  return <div className="max-w-4xl mx-auto">
    <FrameL Image={BackgroundImage} ratio="5:4">
      <div>
        <stack-l class="max-w-2xl m-4 sm:mx-auto px-4 py-8 text-center items-center" space="var(--s3)" style={{ backgroundColor: BoxColor, color: TextColor }}>
          <ReactMarkdown className="text-2xl">{Content}</ReactMarkdown>
          {ShowButton && <a href={ButtonLink} className="btn text-2xl" style={{ backgroundColor: ButtonColor, color: ButtonTextColor }}>{ButtonText}</a>}
        </stack-l>
      </div>
    </FrameL>
  </div>
}

const PopupGraphic = ({ cmsData }) => {
  const { Graphic } = cmsData
  return <Img className="mx-auto" Image={Graphic}></Img>
}

export const PopupControl = ({ cmsData = {} }) => {
  const { ShowOnlyOnce, Duration, PageRestrictions = null, Disabled, UniquePopupId, Type } = cmsData;

  // if there are pageRestrictions or showOnlyOnce then let useEffect handle initialState
  const [isOpen, setIsOpen] = useState(!ShowOnlyOnce && !PageRestrictions);
  const location = useLocation();

  useEffect(() => {
    if (Duration) {
      setTimeout(() => {
        setIsOpen(false);
      }, Duration * 1000);
    }

    let isOpenBuilder = true;
    if (ShowOnlyOnce && localStorage.getItem(`isPopupShown-${UniquePopupId}`) === 'true') {
      isOpenBuilder &= false;
    }

    if (isOpenBuilder && PageRestrictions) {
      const paths = PageRestrictions.split('');
      if (!paths.includes(location.pathname)) {
        isOpenBuilder &= false;
      }
    }
    setIsOpen(isOpenBuilder)
  }, []);

  const handleClose = () => {
    if (ShowOnlyOnce) {
      localStorage.setItem(`isPopupShown-${UniquePopupId}`, "true");
    }
    setIsOpen(false);
  }

  return <>
    <Modal isOpen={isOpen} onClose={handleClose}>
      {Type[0]?.__component === 'section.popup-graphic' ?
        <PopupGraphic cmsData={Type[0]} /> :
        <PopupDetailed cmsData={Type[0]} />}
    </Modal>
    {/* <SnowFlakeEffect /> */}
  </>
}


const SnowFlakeEffect = () => {
  return (
    <div class="snowflakes" aria-hidden="true">
      <div class="snowflake">
        ❅
      </div>
      <div class="snowflake">
        ❆
      </div>
      <div class="snowflake">
        ❅
      </div>
      <div class="snowflake">
        ❆
      </div>
      <div class="snowflake">
        ❅
      </div>
      <div class="snowflake">
        ❆
      </div>
      <div class="snowflake">
        ❅
      </div>
      <div class="snowflake">
        ❆
      </div>
      <div class="snowflake">
        ❅
      </div>
      <div class="snowflake">
        ❆
      </div>
      <div class="snowflake">
        ❅
      </div>
      <div class="snowflake">
        ❆
      </div>
    </div>
  )
}