import React from 'react';
import { useForm } from 'react-hook-form';

export default ({ onSubmit }) => {
    const { register, handleSubmit, errors, reset } = useForm();

    return <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-8 px-4 w-full md:w-1/2 inline-block">
            <label>First Name</label>
            <input ref={register({ required: true })} className="w-full py-2 border-b border-gray-600" type="text" name="firstName" placeholder="First Name" />
            {errors.firstName && <div className="error">Your first name is required</div>}
        </div>
        <div className="mb-8 px-4 w-full md:w-1/2 inline-block">
            <label>Last Name</label>
            <input ref={register({ required: true })} className="w-full py-2 border-b border-gray-600" type="text" name="lastName" placeholder="Last Name" />
            {errors.lastName && <div className="error">Your last name is required</div>}
        </div>
        <div className="mb-8 px-4 w-full inline-block">
            <label>Email Address</label>
            <input ref={register({ required: true })} className="w-full py-2 border-b border-gray-600" type="text" name="emailAddress" placeholder="Email Address" />
            {errors.emailAddress && <div className="error">An email address is required</div>}
        </div>
        <button type="submit" className="bg-primary-blue text-white w-full px-8 py-4 text-lg">Submit</button>
    </form>;
}