import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useFavouriteContext, actions } from 'context/favourite';
import AmpEmailAPI from 'api/AmpEmailAPI';
import { LoaderComponent } from 'component/Loader';

export default ({ Rooms, Packages, LMS, FortyEightHours }) => {
    const { executeRecaptcha } = useGoogleReCaptcha()   ;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [success, setSuccess] = useState(null);

    const { register, handleSubmit, errors, reset } = useForm();

    const { dispatch, state } = useFavouriteContext();
    const { sharing } = state;
    const { contentType, id } = sharing;
    let selectedContentArray = [];
    
    if (contentType === 'Rooms') {
        selectedContentArray = Rooms;
    } else if (contentType === "Packages") {
        selectedContentArray = Packages;
    } else if (contentType === "LMS") {
        selectedContentArray = LMS;
    } else if (contentType === "48Hours") {
        selectedContentArray = FortyEightHours;
    }

    const selectedContent = selectedContentArray.find((e => e.id === id)) || {};

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const { firstName, lastName, emailAddress, message } = data;
        const captchaResponse = await executeRecaptcha('contact');
        let success;

        if (contentType === 'Rooms') {
            const { name, description, price, carousel } = selectedContent;
            success = await AmpEmailAPI.shareFavoriteRoom(firstName, lastName, emailAddress, message, captchaResponse, id, name, description, price, carousel[0]);
        } else if (contentType === "Packages") {
            const { Name, Description, Price, Photo, StartDate, EndDate, RoomName } = selectedContent;
            success = await AmpEmailAPI.shareFavoritePackage(firstName, lastName, emailAddress, message, captchaResponse, id, Name, RoomName, Description, Price || 0, Photo, StartDate, EndDate);
        } else if (contentType === "LMS") {
            const { name, description, price, photo, startDate, endDate, roomName } = selectedContent;
            success = await AmpEmailAPI.shareFavoriteLMS(firstName, lastName, emailAddress, message, captchaResponse, id, name, roomName, description, price || 0, photo, startDate, endDate);
        } else if (contentType === "48Hours") {
            const {Heading, Description, SidePhoto } = selectedContent;
            success = await AmpEmailAPI.shareFavorite48Hours(firstName, lastName, emailAddress, message, captchaResponse, id, Heading, Description, SidePhoto);
        }
        setSuccess(success);
        reset();
        setIsSubmitting(false);
    };

    if (isSubmitting) {
        return <LoaderComponent></LoaderComponent>;
    }

    if (success) {
        return <> 
            <p className="text-center">Thank you for sharing!</p>
            <button onClick={() => setSuccess(null)} className="bg-primary-blue text-white w-full px-8 py-4 text-lg">Share Again</button>
        </>
    } else if (success === false) {
        return <> 
            <p className="text-center">Something went wrong, please try again.</p>
            <button onClick={() => setSuccess(null)} className="bg-primary-blue text-white w-full px-8 py-4 text-lg">Try Again</button>
         </>
    }

    return <>
        <h3 className="text-center">Sharing</h3>
        <p className="text-center">We're delighted that you want to share <strong>{selectedContent.Name || selectedContent.Heading}</strong> with your friends!</p>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-8 px-4 w-full md:w-1/2 inline-block">
                <label>Your Friend's First Name</label>
                <input ref={register({ required: true })} className="w-full py-2 border-b border-gray-600" type="text" name="firstName" placeholder="First Name" />
                {errors.firstName && <div className="error">Your friend's first name is required</div>}
            </div>
            <div className="mb-8 px-4 w-full md:w-1/2 inline-block">
                <label>Your Friend's Last Name</label>
                <input ref={register({ required: true })} className="w-full py-2 border-b border-gray-600" type="text" name="lastName" placeholder="Last Name" />
                {errors.lastName && <div className="error">Your friend's last name is required</div>}
            </div>
            <div className="mb-8 px-4 w-full inline-block">
                <label>Your Friend's Email</label>
                <input ref={register({ required: true })} className="w-full py-2 border-b border-gray-600" type="text" name="emailAddress" placeholder="Email" />
                {errors.emailAddress && <div className="error">An friend's email address is required</div>}
            </div>

            <div className="mb-8 px-4 w-full inline-block">
                <label>Message</label>
                <textarea ref={register()} className="w-full py-2 border-b border-gray-600" type="text" name="message" placeholder="Message" />
            </div>

            <div className="mb-8 mx-4 inline-block flex flex-col items-center">
                <button type="submit" className="bg-primary-blue text-white w-full px-8 py-4 text-lg">Submit</button>
            </div>
        </form>
    </>;
}