import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import ReactGA from 'react-ga'

import App from './App'
import { FavouriteProvider } from 'context/favourite'
import AmpEmailAPI from 'api/AmpEmailAPI'
import ReactPixel from 'react-facebook-pixel';

import './styles/tailwind_output.css'
import './index.css'
import './component/layout'

import * as serviceWorker from './serviceWorker'
import history from './utils/history.js'

const trackingId = 'UA-231034-1' // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId, {
  gaOptions: { allowLinker: true },
})

ReactGA.ga('require', 'linker')
ReactGA.ga('linker:autoLink', [
  'secure.thinkreservations.com',
  'thinkreservations.com',
])

const pixelOptions = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
const pixelId = "568019938806684"; //  for 568019938806684 for Union Inn
ReactPixel.init(pixelId, {}, pixelOptions);

// ReactGA.set({ page: "/index" }); // Update the user's current page
// ReactGA.pageview("/index"); // Record a pageview for the given page
console.log(window.location.pathname)
ReactGA.ga('send', 'pageview', window.location.pathname)
AmpEmailAPI.trackPage(window.location.pathname.replace('/', ''))
ReactPixel.trackSingle(pixelId, "PageView");

history.listen((location) => {
  AmpEmailAPI.trackPage(location.pathname.replace('/', ''))
  ReactGA.set({ page: location.pathname }) // Update the user's current page
  ReactGA.pageview(location.pathname) // Record a pageview for the given page
  ReactPixel.trackSingle(pixelId, "PageView");
})

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <FavouriteProvider>
        <App />
      </FavouriteProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
